$.fn.validateInput = function () {
    $(this).removeClass("state--invalid state--valid");
    if ($(this).find("input, textarea").val() !== "") {

        if($(this).children().hasClass('intl-tel-input')) {
            //tel input
            if ($(this).find('input[type="tel"]').intlTelInput("isValidNumber")) {
                $(this).addClass("state--valid");
            } else {
                $(this).addClass("state--invalid");
            }
        } else {
            if ($(this).find("input, textarea").is(":valid")) {
                $(this).addClass("state--valid");
            } else {
                $(this).addClass("state--invalid");
            }
        }
    }
};