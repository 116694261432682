(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {

        selector.on('change','input[type="number"][data-ajax-link]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            var count = $(this).val();

            $.ajax({
                url: ajaxLink,
                data: {count: count},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        selector.on('click','[data-add-as-product]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            let idProduct = $('#asProductSelect').val();

            $.ajax({
                url: ajaxLink,
                data: {idProduct: idProduct},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        if($('#asProductSelect').length) {
            $('#asProductSelect').select2();
        }

        window.lui[name] = comp;
    }
})("comp_package_extranet");