let checkUlozenkaBranches;

(function($) {

    $('[type="tel"]').intlTelInput({
        nationalMode: false,
        preferredCountries: ['cz', 'sk', 'gb', 'de', 'ru'],
        initialCountry: 'cz'
    });


    cssLoaded(function(){
        $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    checkUlozenkaBranches = function()
    {
        let selectedId = parseInt($('input[name="shipping"]:checked').val()) || 0;
        if(selectedId === 4) {
            $('select[name="ulozenkaBranches"]').slideDown();
        } else {
            $('select[name="ulozenkaBranches"]').slideUp();
        }
    };

    doc.on('change','[data-service-item]',function() {
        var ajaxLink = $(this).attr('data-ajax-link');
        let ajaxData = {};

        if($('input.packeta-selector-open').length && $('input.packeta-selector-open').prop('checked') === true) {
            let pointId = $('.packeta-selector-branch-id').val();
            let deliveryId = $('input.packeta-selector-open').attr('value');
            let pointName = $('input[name="pickup_point_name_' + deliveryId + '"]').val();

            ajaxData = {
                deliveryId: deliveryId,
                pointId: pointId,
                pointName: pointName
            };
        }


        $.ajax({
            url: ajaxLink,
            method: 'post',
            dataType: 'json',
            data: ajaxData
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                checkUlozenkaBranches();
            });
        });
    });

    nl_lib_dialog.init(function(){
        Object.keys(window.lui.lib).forEach(function(key) {
            let dialog_selector = doc.find(".lib--dialog");
            let s = window.lui.lib[key].selector;

            if (dialog_selector.find(s[0]).length) {
                window.lui.lib[key](dialog_selector.find(s[0]),s[1])
            }
        });
    });

    $.libInit(".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit("[data-lib-switch]", "lib_switch", function(selector) {
        $(selector).nl_lib_switch();
    });

    $.libInit("[data-lib-rellax]", "lib_rellax", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.rellax, function() {
                bodyLoaded(function(){
                    new Rellax(selector, {
                        center: true
                    });
                });
            });
        }
    });

    $.libInit("[data-lib-fancybox]", "lib_gallery", function(selector) {
        bodyLoaded(function(){
            $.importStyle(cdnjs.fancybox_css);
            $.importScript(cdnjs.fancybox, function(){
                $(selector).each(function(){
                    let id = $(this).data("lib-fancybox");
                    $(this).fancybox({
                        selector: `[data-lib-fancybox="${id}"] .lib--fancybox-item`,
                        transitionEffect: "slide",
                        animationEffect: "zoom",
                        buttons: [
                            "close"
                        ]
                    });
                });
                $("[data-lib-fancybox-open]").on("click", function(){
                    let id = $(this).data("lib-fancybox-open");
                    $(`[data-lib-fancybox="${id}"] .col:first-of-type .lib--fancybox-item`).trigger("click")
                })
            });
        });
    });

    doc.on('click','[data-submit-form]',function() {

        $(this).closest('form').submit();

    });

    doc.on("click", "[data-add-cart]", function(e){
        $("body").append('<div class="layout_summary_circle"></div>');

        let summary_circle =  $(".layout_summary_circle");
        summary_circle.css({
            "top":  e.clientY + "px",
            "left":  e.clientX + "px"
        });

        summary_circle.animate({
            top: "32px",
            left: "99%",
            width: 16,
            height: 16,
            opacity: 0.1,
        }, 800, function () {
            summary_circle.remove();
        });
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let $el = $(this);

            $.ajax({
                url: $el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload);
                }
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
                success: function (payload) {
                    fn_ajaxHandler(payload,function() {

                        $('[type="tel"]').intlTelInput({
                            nationalMode: false,
                            preferredCountries: ['cz', 'sk', 'gb', 'de', 'ru'],
                            initialCountry: 'cz'
                        });

                    });

                    if(typeof payload.dialog !== 'undefined' && payload.dialog.length) {
                        nl_lib_dialog.open(payload.dialog, function() {

                        });
                    }

                }
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    /*sr.sync();*/
                });
            });
        });
    })();

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    setTimeout(function(){
        $.libInit("[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    },2500);
})(jQuery);