$.libInit("[data-ui-rating]", "lib_ui_rating", function(selector) {
    $(selector).each(function(){
        let elm = $(this);
        let default_values = elm.html();

        elm.on("mouseenter", ".icon", function () {
            $(this).siblings().removeClass("state--active state--half");
            $(this).prevAll().addClass("state--active");
            $(this).addClass("state--active");
        });

        elm.on("mouseleave", function(){
            $(this).html(default_values);
        });

        elm.on("click", ".icon", function() {
            let index = $(this).index();

            if (!elm.find("input").length) {
                elm.off("mouseleave mouseenter click");
                elm.html(default_values).removeAttr("data-ui-rating");
            } else {
                elm.find("input").attr("value",index+1);
                default_values = elm.html();
            }
        });
    });
});