(function part(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        selector.on("click",".wrp_part_head",function () {
            let el = $(this).closest(selector),
                body = el.find(".wrp_part_body");

            if(el.hasClass("state--active")) {
                el.removeClass("state--open");
                body.stop().slideUp(function(){
                    el.removeClass("state--active");
                })
            }
            else {
                el.addClass("state--open");
                body.stop().slideDown(function(){
                    el.addClass("state--active");
                })
            }
        });

        window.lui[name] = part;
    }
})("part_item_faq");