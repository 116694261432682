let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.1.2/dist/flickity.pkgd.min.js",
    "flickity_sync": "https://cdn.jsdelivr.net/npm/flickity-sync@2.0.0/flickity-sync.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.2/dist/jquery.fancybox.min.css",
    "rellax": "https://cdn.jsdelivr.net/npm/rellax@1.7.1/rellax.min.js"
};