(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header = fn.find(".wrp_header_body");

        fn.after('<nav id="layout_nav" class="ssm-nav"><div class="wrp_nav_content"></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            // logo = header.find(".elm_header_logo").clone(),
            nav = fn.find(".elm_header_nav").clone(),
            social = header.find(".part_social").clone();

        // layout_nav.find(".wrp_nav_head").append(logo);
        layout_nav.find(".wrp_nav_content").append(nav);
        layout_nav.find(".wrp_nav_content").append(social);

        $.importScript(cdnjs.touchswipe, function() {
            $('.ssm-nav').slideAndSwipe();
        });

        $.importScript(cdnjs.headroom, function(){
            let headroom = new Headroom(fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);